import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import moment from "moment";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import { CreditCardIcon } from "../../svg-icons/icon-credit-card";
import { NavigatorBeforeIcon} from "../../svg-icons/icon-navigator-before";

import styles from "./client-details.module.scss";

interface PersonalInfo {
  fullName: string;
  idnp: string;
  phone: string;
  email: string;
  address: string;
  identityDocument: string;
  dob: string;
  riskLevel: string;
}

interface Account {
  id: number;
  alias: string;
  accountNumber: string;
  currency: string;
  balance: number;
}

interface Deposit {
  id: number;
  alias: string;
  startDate: string;
  endDate: string;
  conditions: string;
  amount: number;
}

interface Credit {
  id: number;
  alias: string;
  startDate: string;
  endDate: string;
  amount: number;
  conditions: string;
}

interface Card {
  id: number;
  alias: string;
  cardNumber: string;
  expiryDate: string;
  balance: number;
}

interface ActiveProducts {
  accounts: Account[];
  deposits: Deposit[];
  credits: Credit[];
  cards: Card[];
}

interface Session {
  id: string;
  sessionStart: string;
  ipAddress: string;
  geoLocation: {
    latitude: number;
    longitude: number;
  };
  deviceInfo: {
    platform: string;
    version: string;
    manufacturer: string;
    model: string;
  };
  appVersion: string;
}

interface Client {
  id: number;
  personalInfo: PersonalInfo;
  activeProducts: ActiveProducts;
  sessions: Session[];
  status: "ACTIVE" | "INACTIVE";
}

const initialMockData: Client[] = [
  {
    id: 7625,
    personalInfo: {
      fullName: "Nicolae Popa",
      idnp: "2002345612453",
      phone: "+373 60 123 456",
      email: "nicolae.popa@example.com",
      address: "mun. Chișinău, bd. Dacia 22/2",
      identityDocument: "ID123456789",
      dob: "13-08-1995",
      riskLevel: "1",
    },
    activeProducts: {
      accounts: [
        {
          id: 1,
          alias: "Main Account",
          accountNumber: "1234567890",
          currency: "USD",
          balance: 1500.5,
        },
      ],
      deposits: [],
      credits: [],
      cards: [],
    },
    sessions: [
      {
        id: "session1",
        sessionStart: "2024-11-10T12:34:56Z",
        ipAddress: "192.168.1.1",
        geoLocation: { latitude: 37.7749, longitude: -122.4194 },
        deviceInfo: {
          platform: "iOS",
          version: "14.7.1",
          manufacturer: "Apple",
          model: "iPhone 12",
        },
        appVersion: "2.3.4",
      },
    ],
    status: "ACTIVE",
  },
];

const ClientDetails: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientData, setClientData] = useState<Client | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedProductType, setSelectedProductType] = useState<
    keyof ActiveProducts | null
  >(null);

  useEffect(() => {
    const client = initialMockData.find((c) => c.id === Number(clientId));
    setClientData(client || null);
  }, [clientId]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    setSelectedProductType(null);
  };

  const handleShowDetails = (productType: keyof ActiveProducts) => {
    setSelectedProductType(productType);
  };

  const renderInfoBlock = () => {
    if (!clientData) return null;

    return (
      <div className={styles.info_block}>
        <div className={styles.info_block_title}>
          <span>Client Details</span>
        </div>
        <div>
          {Object.entries(clientData.personalInfo).map(([key, value]) => (
            <div key={key} className={styles.info_block_input_container}>
              <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
              <div className={styles.block_value}>{value}</div>
            </div>
          ))}
          <div className={styles.info_block_input_container}>
            <label>Status</label>
            <div className={styles.block_value}>
              {clientData.status === "ACTIVE" ? (
                <span className={styles.status_active}>ACTIVE</span>
              ) : (
                <span className={styles.status_inactive}>INACTIVE</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderProducts = () => {
    if (!clientData) return null;
    const { accounts, deposits, credits, cards } = clientData.activeProducts;
    const productCounts = [
      {
        name: "Conturi",
        count: accounts.length,
        type: "accounts",
        icon: <CreditCardIcon />,
      },
      { name: "Depozite", count: deposits.length, type: "deposits" },
      { name: "Credite", count: credits.length, type: "credits" },
      { name: "Carduri", count: cards.length, type: "cards" },
    ];

    if (selectedProductType) {
      return renderProductDetails(selectedProductType);
    }

    return (
      <div className={styles.products_container}>
        {productCounts.map((product, index) => (
          <div key={index} className={styles.product_card}>
            <div className={styles.top_wrapper_card}>
              <div className={styles.top_block_card}>
                <p>{product.count}</p>
                <h3>{product.name}</h3>
              </div>
              <div className={styles.product_icon}>{product.icon}</div>
            </div>

            <button
              className={styles.details_button}
              onClick={() =>
                handleShowDetails(product.type as keyof ActiveProducts)
              }
              disabled={product.count === 0}
            >
              Vezi detalii →
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderProductDetails = (productType: keyof ActiveProducts) => {
    if (!clientData) return null;

    const products = clientData.activeProducts[productType];

    if (products.length === 0) {
      return <p>Nu există {productType} disponibile.</p>;
    }

    const columns = getColumnsForProductType(productType);

    return (
      <div>
        <div
          className={styles.back_button}
          onClick={() => setSelectedProductType(null)}
        >
          <NavigatorBeforeIcon /> Înapoi
        </div>
        <DataTableComponent
          columns={columns}
          rows={products}
          checkboxSelection={false}
          isFetching={false}
          idChangeValue={"id"}
          className={styles.data_table}
        />
      </div>
    );
  };

  const getColumnsForProductType = (productType: keyof ActiveProducts) => {
    switch (productType) {
      case "accounts":
        return [
          { field: "id", headerName: "ID", flex: 0.5 },
          { field: "alias", headerName: "Alias", flex: 0.8 },
          { field: "accountNumber", headerName: "Account Number", flex: 1 },
          { field: "currency", headerName: "Currency", flex: 0.5 },
          { field: "balance", headerName: "Balance", flex: 0.5 },
        ];
      case "deposits":
        return [
          { field: "id", headerName: "ID", flex: 0.5 },
          { field: "alias", headerName: "Alias", flex: 1 },
          { field: "startDate", headerName: "Start Date", flex: 1 },
          { field: "endDate", headerName: "End Date", flex: 1 },
          { field: "conditions", headerName: "Conditions", flex: 1 },
          { field: "amount", headerName: "Amount", flex: 1 },
        ];
      case "credits":
        return [
          { field: "id", headerName: "ID", flex: 0.5 },
          { field: "alias", headerName: "Alias", flex: 1 },
          { field: "startDate", headerName: "Start Date", flex: 1 },
          { field: "endDate", headerName: "End Date", flex: 1 },
          { field: "amount", headerName: "Amount", flex: 1 },
          { field: "conditions", headerName: "Conditions", flex: 1 },
        ];
      case "cards":
        return [
          { field: "id", headerName: "ID", flex: 0.5 },
          { field: "alias", headerName: "Alias", flex: 1 },
          { field: "cardNumber", headerName: "Card Number", flex: 1 },
          { field: "expiryDate", headerName: "Expiry Date", flex: 1 },
          { field: "balance", headerName: "Balance", flex: 1 },
        ];
      default:
        return [];
    }
  };

  const renderSessions = () => {
    if (!clientData) return null;

    const sessions = clientData.sessions;

    if (sessions.length === 0) {
      return <p>Nu există sesiuni disponibile.</p>;
    }

    return (
      <table className={styles.sessions_table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Data și ora</th>
            <th>Adresă IP</th>
            <th>Informații dispozitiv</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session) => (
            <tr key={session.id}>
              <td>{session.id}</td>
              <td>{moment(session.sessionStart).format("DD-MM-YYYY HH:mm")}</td>
              <td>{session.ipAddress}</td>
              <td>
                {session.deviceInfo.platform} {session.deviceInfo.version} -{" "}
                {session.deviceInfo.manufacturer} {session.deviceInfo.model}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <AppMainLayout navBarTitle={`Client #${clientId}`} noBackground>
      {renderInfoBlock()}
      <div className={styles.custom_tabs_container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab_button} ${
              activeTab === 0 ? styles.active_tab : ""
            }`}
            onClick={() => handleTabChange(0)}
          >
            Produse
          </div>
          <div
            className={`${styles.second_tab_button} ${
              activeTab === 1 ? styles.active_tab : ""
            }`}
            onClick={() => handleTabChange(1)}
          >
            Sesiunile utilizatorului
          </div>
        </div>
        <div className={styles.tab_content}>
          {activeTab === 0 ? renderProducts() : renderSessions()}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default ClientDetails;
