import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import styles from './settings-modal.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SettingsModalProps {
  showSettingsModal: boolean;
  setShowSettingsModal: (show: boolean) => void;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({
  showSettingsModal,
  setShowSettingsModal,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setShowSettingsModal(false);
  };

  return (
    <Dialog 
      open={showSettingsModal} 
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <div className={styles.custom_modal_container}>
        <DialogTitle className={styles.setting_modal_title}>
          {t('Settings')}
        </DialogTitle>
        <DialogContent className={styles.modal_content}>
          {/* Add settings content here */}
        </DialogContent>
        <DialogActions className={styles.action_buttons}>
          <Button
            onClick={handleClose}
            variant="outlined"
            className={styles.cancel_button}
            color="primary"
            size="large"
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
