import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../infrastructure/components/Button";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { ITopNavBarComponentProps } from "./types";
import { getIsAuthFetching } from "../../../api/selectors/authSelector";
import { getAccessToken } from "../../../api/selectors/tokenSelector";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./top-nav-bar.module.scss";
import classNames from "classnames";
import { SettingsIcon } from "../../../svg-icons/icon-settings";
import { NotificationsIcon } from "../../../svg-icons/icon-notifications";
import { Link } from "react-router-dom";
import { navigation } from "../../../constants/data";
import { getNotificationCount, getPrevNotificationCount } from "../../../api/selectors/mainSelector";
import { Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import axios from "../../../api/services/Api";
import ButtonComponent from "../ButtonComponent";
import { setToken, setTokenInHeaders } from "../../../api/actionCreators/authActionCreator";
import { ExitIcon } from "../../../svg-icons/icon-exit";
import { PersonIcon } from "../../../svg-icons/icon-person";
import Drawer from "@mui/material/Drawer";
import FormLabeledInput from "../FormComponents/FormLabeledInput/FormLabeledInput";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import { SettingsModal } from "../SettingsModal/SettingsModal";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TopNavBarComponent({
  navBarTitle,
  className,
  breadcrumbs = null,
  onExit,
}: ITopNavBarComponentProps) {
  const mockMerchantInfoDefault = {
    merchantId: null,
    legalName: null,
    tradeName: null,
    idno: null,
    iban: null,
    userId: null,
    idnp: null,
    phoneNumber: null,
    email: null,
  };

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [merchantInfo, setMerchantInfo] = useState({
    ...mockMerchantInfoDefault,
  });

  const data = useSelector(getAccessToken);
  const token = useSelector(getAccessToken);
  const isAuthFetching = useSelector(getIsAuthFetching);
  const dispatch = useDispatch();

  const renderExitModal = () => {
    const handleLogout = async () => {
      try {
        const token = localStorage.getItem('_session_token');
        const response = await axios.post('/admin/auth/logout', null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data.result === "SUCCESS") {
          dispatch(setToken(null));
          dispatch(setTokenInHeaders(false));
          localStorage.removeItem('_session_token');
          window.location.href = '/';
        }
      } catch (error) {
        console.error('Logout error:', error);
      }
      setOpen(false);
    };

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Logout")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_logout")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <ButtonComponent
              title={t("Exit")}
              onClick={handleLogout}
              disabled={isAuthFetching}
              icon={isAuthFetching && 
                <CircularProgress className="circular-progress" size={18} />
              }
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              onClick={() => setOpen(false)}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const renderUserInfoDrawer = () => {
    return (
      <SwipeableDrawer
        anchor={"right"}
        open={showUserInfo}
        onClose={() => setShowUserInfo(false)}
        onOpen={() => setShowUserInfo(true)}
      >
        <Box
          sx={{ width: 450 }}
          role="presentation"
          // onClick={() => setShowUserInfo(false)}
          // onKeyDown={() => setShowUserInfo(false)}
        >
          <List>
            <h3>{t("Merchant_info")}</h3>
            <div className={styles.merchant_info}>
              <div>{t("Merchant_id")}</div>
              <div>{merchantInfo?.merchantId}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Legal_name")}</div>
              <div>{merchantInfo?.legalName}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Trade_name")}</div>
              <div>{merchantInfo?.tradeName}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Merchant_idno")}</div>
              <div>{merchantInfo?.idno}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Merchnat_iban")}</div>
              <div>{merchantInfo?.iban}</div>
            </div>
            <Divider />
            <h3>{t("Admin_info")}</h3>
            <div className={styles.merchant_info}>
              <div>{t("Admin_id")}</div>
              <div>#{merchantInfo?.userId}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Admin_idnp")}</div>
              <div>{merchantInfo?.idnp}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Phone_number")}</div>
              <div>{merchantInfo?.phoneNumber}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>Email</div>
              <div>{merchantInfo?.email}</div>
            </div>
          </List>
          <Divider />
        </Box>
      </SwipeableDrawer>
    );
  };

  return (
    <>
      {renderExitModal()}
      {renderUserInfoDrawer()}
      {showSettingsModal && (
        <SettingsModal {...{ setShowSettingsModal, showSettingsModal }} />
      )}
      {redirect && <Redirect to="/" />}
      <div
        className={classNames({
          [`${styles.top_nav_bar_wrapper}`]: true,
          [className]: true,
        })}
      >
        <div className={styles.top_bar_page_name}>
          {breadcrumbs && breadcrumbs.step2Label && (
            <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
              <Link to={breadcrumbs.step1Url}>
                <Typography>{breadcrumbs.step1Label}</Typography>
              </Link>
              /{" "}
              {breadcrumbs.step2Label && (
                <Typography>{breadcrumbs.step2Label}</Typography>
              )}
            </Breadcrumbs>
          )}
          <Typography
            className={styles.page_title}
            component={"span"}
            variant={"body2"}
          >
            {navBarTitle}
          </Typography>
        </div>
        <div className={styles.top_bar_page_user_wrapper}>
          {/* <div className={styles.notification_icon_wrapper}>
            <NotificationsIcon />
          </div> */}
          <div className={styles.settings_icon_wrapper}>
            <SettingsIcon onClick={
              // () => setShowSettingsModal(true)
              null
              } />
          </div>

          <div className={styles.user_name_info_wrapper}>
            <div
              className={styles.user_login}
              onClick={
                // () => setShowUserInfo(true)
                null
              }
            >
              <span>{data?.phoneNumber}</span>
              <PersonIcon />
            </div>
          </div>
          <div
            className={styles.notification_icon_wrapper}
            onClick={() => setOpen(true)}
          >
            <ExitIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavBarComponent;
