import React, { useState, useEffect, useCallback, ChangeEvent, FormEvent } from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { SearchIcon } from "../../svg-icons/icon-search";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
import { PlusIcon } from "../../svg-icons/icon-plus";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import debounce from "lodash.debounce";
import styles from "./users.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface User {
  id: number;
  username: string;
  role: "ADMIN" | "EMPLOYEE";
  lastLogin: string;
  email: string;
  name: string;
  phone: string;
  createdAt: string;
}

interface FormErrors {
  username?: string;
  password?: string;
  email?: string;
  role?: string;
  phone?: string;
  name?: string;
}

interface Column {
  field: keyof User;
  headerName: string;
  flex: number;
  headerClassName: string;
  cellClassName: string;
  renderCell: (params: { row: User }) => React.ReactNode;
}

const filtersRoles = ["all", "ADMIN", "EMPLOYEE"] as const;

const initialMockApiResponse: { result: string; data: User[] } = {
  result: "SUCCESS",
  data: [
    {
      id: 1,
      username: "andrei_ionescu",
      role: "ADMIN",
      lastLogin: "2024-11-10T12:34:56Z",
      email: "andrei.ionescu@example.md",
      name: "Andrei Ionescu",
      phone: "+373 69 11 22 33",
      createdAt: "2024-01-01T09:00:00Z",
    },
    {
      id: 10,
      username: "marius_pop",
      role: "EMPLOYEE",
      lastLogin: "2024-11-03T17:55:25Z",
      email: "marius.pop@example.md",
      name: "Marius Pop",
      phone: "+373 69 11 22 33",
      createdAt: "2024-10-12T13:50:00Z",
    },
  ],
};

const Users: React.FC = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>(initialMockApiResponse.data);
  const [rows, setRows] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [roleFilter, setRoleFilter] = useState<"all" | "ADMIN" | "EMPLOYEE">("all");
  const [totalCount, setTotalCount] = useState<number>(initialMockApiResponse.data.length);
  const [error, setError] = useState<string | null>(null);

  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);

  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const [newUser, setNewUser] = useState<{
    username: string;
    password: string;
    email: string;
    role: "ADMIN" | "EMPLOYEE";
    name: string;
    phone: string;
  }>({
    username: "",
    password: "",
    email: "",
    role: "EMPLOYEE",
    name: "",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (!newUser.username.trim()) errors.username = t("Required_field");
    if (!newUser.password.trim()) errors.password = t("Required_field");
    if (!newUser.email.trim()) {
      errors.email = t("Required_field");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(newUser.email)) {
        errors.email = t("Invalid_email");
      }
    }
    if (!["ADMIN", "EMPLOYEE"].includes(newUser.role)) {
      errors.role = t("Invalid_role");
    }
    if (!newUser.phone.trim()) {
      errors.phone = t("Required_field");
    } else {
      const phoneRegex = /^\+373\s(69|68|78)\s\d{2}\s\d{2}\s\d{2}$/;
      if (!phoneRegex.test(newUser.phone)) {
        errors.phone = t("Invalid_phone");
      }
    }
    if (!newUser.name.trim()) {
      errors.name = t("Required_field");
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchUsers = useCallback(() => {
    setIsFetching(true);
    setError(null);

    setTimeout(() => {
      try {
        const response = { result: "SUCCESS", data: users };

        if (response.result === "SUCCESS") {
          let filteredUsers = response.data;

          if (roleFilter !== "all") {
            filteredUsers = filteredUsers.filter((user) => user.role === roleFilter);
          }

          if (searchText.trim() !== "") {
            const searchLower = searchText.toLowerCase();
            filteredUsers = filteredUsers.filter(
              (user) =>
                user.username.toLowerCase().includes(searchLower) ||
                user.name.toLowerCase().includes(searchLower) ||
                user.phone.includes(searchLower)
            );
          }

          setTotalCount(filteredUsers.length);

          const start = currentPage * pageSize;
          const paginatedUsers = filteredUsers.slice(start, start + pageSize);

          setRows(paginatedUsers);
        } else {
          setError(t("Failed_to_fetch_users"));
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setError(t("Failed_to_fetch_users"));
      } finally {
        setIsFetching(false);
      }
    }, 1000);
  }, [roleFilter, searchText, currentPage, pageSize, users, t]);

  const debouncedFetchUsers = useCallback(
    debounce(() => {
      fetchUsers();
    }, 500),
    [fetchUsers]
  );

  useEffect(() => {
    debouncedFetchUsers();

    return () => {
      debouncedFetchUsers.cancel();
    };
  }, [debouncedFetchUsers]);

  const paginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(0);
  };

  const handleRoleFilterChange = (role: "all" | "ADMIN" | "EMPLOYEE") => {
    setRoleFilter(role);
    setCurrentPage(0);
  };

  const renderColumns = (): Column[] => {
    return [
      {
        field: "email",
        headerName: t("Email"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div
            className={styles.email_cell}
            onClick={() => handleEmailClick(row)}
            style={{ cursor: "pointer" }}
          >
            {row.email}
          </div>
        ),
      },
      {
        field: "name",
        headerName: t("Name_surname"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div className={styles.no_underline}>{row.name}</div>
        ),
      },
      {
        field: "phone",
        headerName: t("Phone"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => row.phone,
      },
      {
        field: "createdAt",
        headerName: t("Registration_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) =>
          moment(row.createdAt).format("DD-MM-YYYY HH:mm"),
      },
      {
        field: "role",
        headerName: t("Role"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div className={styles.role_wrapper}>{row.role}</div>
        ),
      },
    ];
  };

  const handleEmailClick = (user: User) => {
    setSelectedUser(user);
    setOpenPasswordModal(true);
  };

  const renderFilterItems = () => {
    return filtersRoles.map((filter) => (
      <button
        key={filter}
        className={classNames({
          [styles.filter_button]: true,
          [styles.filter_button_active]: roleFilter === filter,
        })}
        onClick={() =>
          handleRoleFilterChange(filter as "all" | "ADMIN" | "EMPLOYEE")
        }
      >
        {filter === "all"
          ? t("All")
          : t(filter.charAt(0).toUpperCase() + filter.slice(1).toLowerCase())}
      </button>
    ));
  };

  const openAddUserModalHandler = () => {
    setOpenAddUserModal(true);
  };

  const closeAddUserModal = () => {
    setOpenAddUserModal(false);
    setNewUser({
      username: "",
      password: "",
      email: "",
      role: "EMPLOYEE",
      name: "",
      phone: "",
    });
    setFormErrors({});
  };

  const handleNewUserChange = (field: keyof typeof newUser, value: string) => {
    setNewUser((prev) => ({
      ...prev,
      [field]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  const handleAddNewUser = () => {
    if (validateForm()) {
      const newUserEntry: User = {
        id: users.length > 0 ? users[users.length - 1].id + 1 : 1,
        username: newUser.username,
        role: newUser.role,
        lastLogin: new Date().toISOString(),
        email: newUser.email,
        name: newUser.name,
        phone: newUser.phone,
        createdAt: new Date().toISOString(),
      };

      setUsers((prevUsers) => [...prevUsers, newUserEntry]);
      fetchUsers();
      closeAddUserModal();
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const handlePasswordSubmit = () => {
    if (!password.trim()) {
      setPasswordError(t("Required_field"));
      return;
    }
    setPassword("");
    setSelectedUser(null);
    setOpenPasswordModal(false);
  };

  const closePasswordModalHandler = () => {
    setPassword("");
    setSelectedUser(null);
    setPasswordError(null);
    setOpenPasswordModal(false);
  };

  return (
    <AppMainLayout navBarTitle={t("Users")}>
      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            placeholder={t("Search_by_username_name_phone")}
            value={searchText}
            onChange={handleSearchChange}
            className={styles.search_input}
          />
        </FormControl>

        <Button
          variant="primary"
          icon={<PlusIcon />}
          label={t("New")}
          // disabled={isFetching}
          onClick={openAddUserModalHandler}
          className={styles.add_new_btn}
        />
      </div>

      <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      {error && <div className={styles.error_message}>{error}</div>}

      <DataTableComponent
        checkboxSelection={false}
        columns={renderColumns()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.users_table}
        rows={rows}
      />

      <Pagination
        onClick={paginationClick}
        onChangePageSize={handlePageSizeChange}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={pageSize}
      />

      <Dialog
        open={openAddUserModal}
        onClose={closeAddUserModal}
        aria-labelledby="add-user-dialog-title"
        aria-describedby="add-user-dialog-description"
        className={styles.custom_dialog}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle
            id="add-user-dialog-title"
            className={styles.setting_modal_title}
          >
            {t("Add_New_User")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText id="add-user-dialog-description">
              {/* Поле Username */}
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="username">{t("Username")}</label>
                <TextField
                  id="username"
                  variant="outlined"
                  placeholder={t("Username")}
                  fullWidth
                  value={newUser.username}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("username", e.target.value)
                  }
                  error={!!formErrors.username}
                  helperText={formErrors.username}
                  className={styles.modal_input}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="name">{t("Name")}</label>
                <TextField
                  id="name"
                  variant="outlined"
                  fullWidth
                  placeholder={t("Name")}
                  value={newUser.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("name", e.target.value)
                  }
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  className={styles.modal_input}
                />
              </div>

              {/* Поле Password */}
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="password">{t("Password")}</label>
                <TextField
                  id="password"
                  variant="outlined"
                  type="password"
                  placeholder={t("Password")}
                  fullWidth
                  value={newUser.password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("password", e.target.value)
                  }
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                  className={styles.modal_input}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="email">{t("Email")}</label>
                <TextField
                  id="email"
                  variant="outlined"
                  type="email"
                  placeholder={t("Email")}
                  fullWidth
                  value={newUser.email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("email", e.target.value)
                  }
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  className={styles.modal_input}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="role">{t("Role")}</label>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formErrors.role}
                  className={styles.modal_input}
                >
                  <Select
                    id="role"
                    value={newUser.role}
                    onChange={(e: SelectChangeEvent<string>) =>
                      handleNewUserChange("role", e.target.value as "ADMIN" | "EMPLOYEE")
                    }
                  >
                    <MenuItem value="ADMIN">{t("ADMIN")}</MenuItem>
                    <MenuItem value="EMPLOYEE">{t("EMPLOYEE")}</MenuItem>
                  </Select>
                  {formErrors.role && (
                    <FormHelperText>{formErrors.role}</FormHelperText>
                  )}
                </FormControl>
              </div>

              {/* Поле Phone */}
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="phone">{t("Phone")}</label>
                <TextField
                  id="phone"
                  variant="outlined"
                  fullWidth
                  placeholder="+373 69 00 00 00"
                  value={newUser.phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("phone", e.target.value)
                  }
                  error={!!formErrors.phone}
                  helperText={formErrors.phone}
                  className={styles.modal_input}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              icon={<PlusIcon />}
              label={t("New")}
              disabled={isFetching}
              onClick={isFetching ? undefined : handleAddNewUser}
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              disabled={isFetching}
              onClick={closeAddUserModal}
            />
          </DialogActions>
        </div>
      </Dialog>

      {/* Модальное окно ввода пароля */}
      <Dialog
        open={openPasswordModal}
        onClose={closePasswordModalHandler}
        aria-labelledby="password-dialog-title"
        aria-describedby="password-dialog-description"
        className={styles.custom_dialog}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle
            id="password-dialog-title"
            className={styles.setting_modal_title}
          >
            {t("Enter_Password")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText id="password-dialog-description">
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="password-input">{t("Password")}</label>
                <TextField
                  id="password-input"
                  variant="outlined"
                  type="password"
                  placeholder={t("Password")}
                  fullWidth
                  value={password}
                  onChange={handlePasswordChange}
                  error={!!passwordError}
                  helperText={passwordError}
                  className={styles.modal_input}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              label={t("Submit")}
              onClick={handlePasswordSubmit}
              disabled={isFetching}
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              onClick={closePasswordModalHandler}
              disabled={isFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    </AppMainLayout>
  );
};

export default Users;
