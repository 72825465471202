import React from "react";

export const NavigatorBeforeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.7051C14.61 6.3151 13.98 6.3151 13.59 6.7051L8.99999 11.2951C8.60999 11.6851 8.60999 12.3151 8.99999 12.7051L13.59 17.2951C13.98 17.6851 14.61 17.6851 15 17.2951C15.39 16.9051 15.39 16.2751 15 15.8851L11.12 11.9951L15 8.1151C15.38 7.7251 15.38 7.0851 15 6.7051Z"
        fill="#A4A5B1"
      />
    </svg>
  );
};
