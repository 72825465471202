import {
    SET_AUTH_DATA,
    AUTH_START,
    AUTH_STOP,
    FETCH_TOKEN_SUCCESS,
    LOGOUT_START,
    AUTH_FINISH,
    SET_EXPIRED_TIME,
    SET_PAGE_RELOADED,
    SET_TOKEN_IN_HEADERS,
    CHECK_IF_TOKEN_EXPIRED,
    ON_FIRE_REFRESH_TOKEN,
    SET_MODE_STATUS,
    SET_TOKEN
} from '../actions';

export const signIn = (authData: any) => {
    return { type: SET_AUTH_DATA, payload: authData };
};

export const authStart = () => {
    return { type: AUTH_START };
};

export const setTokenInHeaders = (state) => {
    return { type: SET_TOKEN_IN_HEADERS, payload: state };
};

export const authEnd = () => {
    return { type: AUTH_STOP };
};

export const logoutStart = () => {
    return { type: LOGOUT_START };
};
export const logoutFinish = () => {
    return { type: AUTH_FINISH };
};


export const setTokenExpired = (state = false) => {
    return { type: CHECK_IF_TOKEN_EXPIRED, payload: state };
};

export const onFireRefreshToken = (state = false) => {
    return { type: ON_FIRE_REFRESH_TOKEN, payload: state };
};

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        payload: token ? {
            accessToken: token,
            refreshToken: token,
            expiresIn: null
        } : null
    };
};

export const setPageReloaded = (state = false) => {
    return { type: SET_PAGE_RELOADED, payload: state };
};

export const setExpiredTime = (expiredTime) => {
    return { type: SET_EXPIRED_TIME, payload: expiredTime };
};

export const setModeStatus = (status) => {
    return { type: SET_MODE_STATUS, payload: status };
};