import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  FormEvent,
} from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { SearchIcon } from "../../svg-icons/icon-search";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
import { RestartIcon } from "../../svg-icons/icon-restart";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "./clients.module.scss";

interface Client {
  id: number;
  idnp: string;
  fullName: string;
  lastActivityDate: string;
  phone: string;
  address: string;
  status: "ACTIVE" | "INACTIVE";
}

interface FormErrors {
  idnp?: string;
  fullName?: string;
  phone?: string;
  address?: string;
  status?: string;
}

const filtersSellers = ["all", "ACTIVE", "INACTIVE"] as const;

const initialMockData: Client[] = [
  {
    id: 7625,
    idnp: "2002345612453",
    fullName: "Nicolae Popa",
    lastActivityDate: "2024-11-10T12:34:56Z",
    phone: "+373 60 123 456",
    address: "mun. Chișinău, bd. Dacia 22/2",
    status: "ACTIVE",
  },
  {
    id: 5678,
    idnp: "2002345612454",
    fullName: "Ion Sandu",
    lastActivityDate: "2024-11-12T14:22:30Z",
    phone: "+373 61 234 567",
    address: "mun. Chișinău, bd. Mircea cel Bătrân 15/3",
    status: "ACTIVE",
  },
  {
    id: 6754,
    idnp: "2002345612455",
    fullName: "Ana Gheorghita",
    lastActivityDate: "2024-11-13T10:15:45Z",
    phone: "+373 62 345 678",
    address: "mun. Chișinău, str. Albișoara 78/1",
    status: "INACTIVE",
  },
];

const Clients: React.FC = () => {
  const { t } = useTranslation();

  const [clients, setClients] = useState<Client[]>(initialMockData);
  const [rows, setRows] = useState<Client[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [idnpText, setIdnpText] = useState<string>("");
  const [phoneText, setPhoneText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sellerStatus, setSellerStatus] = useState<
    "all" | "ACTIVE" | "INACTIVE"
  >("all");
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [newClient, setNewClient] = useState<{
    idnp: string;
    fullName: string;
    phone: string;
    address: string;
    dob: string;
    status: "ACTIVE" | "INACTIVE";
  }>({
    idnp: "",
    fullName: "",
    phone: "",
    address: "",
    dob: "",
    status: "ACTIVE",
  });

  const [totalCount, setTotalCount] = useState<number>(initialMockData.length);

  const handleNewClientChange = (
    field: keyof typeof newClient,
    value: string | "ACTIVE" | "INACTIVE"
  ) => {
    setNewClient((prev) => ({
      ...prev,
      [field]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  const filterAndSearchClients = useCallback(() => {
    let filtered = [...clients];

    if (sellerStatus !== "all") {
      filtered = filtered.filter((client) => client.status === sellerStatus);
    }

    if (idnpText.trim() !== "") {
      filtered = filtered.filter((client) =>
        client.idnp.toLowerCase().includes(idnpText.toLowerCase())
      );
    }
    
    if (phoneText.trim() !== "") {
      filtered = filtered.filter((client) =>
        client.phone.toLowerCase().includes(phoneText.toLowerCase())
      );
    }

    setTotalCount(filtered.length);

    const start = currentPage * pageSize;
    const paginated = filtered.slice(start, start + pageSize);
    setRows(paginated);
  }, [clients, sellerStatus, idnpText, phoneText, currentPage, pageSize]);

  useEffect(() => {
    setIsFetching(true);

    const timer = setTimeout(() => {
      filterAndSearchClients();
      setIsFetching(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [filterAndSearchClients]);

  const paginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleIDNPChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIdnpText(e.target.value);
    setCurrentPage(0);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneText(e.target.value);
    setCurrentPage(0);
  };

  const handleResetFilters = () => {
    setIdnpText("");
    setPhoneText("");
    setSellerStatus("all");
    setCurrentPage(0);
  };

  const renderColumns = () => {
    return [
      {
        field: "idnp",
        headerName: t("IDNP"),
        flex: 0.7,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: Client }) => (
          <div className={styles.no_underline}>{row.idnp}</div>
        ),
      },
      {
        field: "fullName",
        headerName: t("Name_surname"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: Client }) => (
          <Link to={`/app/clients/${row.id}`} className={styles.no_underline}>
            {row.fullName}
          </Link>
        ),
      },
      {
        field: "phone",
        headerName: t("Phone"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: Client }) => row.phone,
      },
      {
        field: "address",
        headerName: t("Address"),
        flex: 1.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: Client }) => row.address,
      },
      {
        field: "lastActivityDate",
        headerName: t("Last_Activity"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: Client }) =>
          moment(row.lastActivityDate).format("DD-MM-YYYY HH:mm"),
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: Client }) => (
          <span
            className={
              row.status === "ACTIVE"
                ? styles.status_active
                : styles.status_inactive
            }
          >
            {t(row.status)}
          </span>
        ),
      },
    ];
  };

  const renderFilterItems = () => {
    return filtersSellers.map((filter) => (
      <button
        key={filter}
        className={classNames({
          [styles.filter_button]: true,
          [styles.filter_button_active]: sellerStatus === filter,
        })}
        onClick={() => setSellerStatus(filter)}
      >
        {filter === "all"
          ? t("All")
          : t(filter.charAt(0).toUpperCase() + filter.slice(1).toLowerCase())}
      </button>
    ));
  };

  return (
    <AppMainLayout navBarTitle={t("Clients")}>
      <div className={styles.customer_page_bottom_container}>
        <FormControl>
          <OutlinedInput
            id="outlined-adornment-idnp"
            startAdornment={<SearchIcon />}
            placeholder={t("IDNP")}
            value={idnpText}
            onChange={handleIDNPChange}
            className={styles.search_input}
            type="number"
          />
        </FormControl>

        <FormControl>
          <OutlinedInput
            id="outlined-adornment-phone"
            startAdornment={<SearchIcon />}
            placeholder={t("Phone")}
            value={phoneText}
            onChange={handlePhoneChange}
            className={styles.search_input}
            type="text"
          />
        </FormControl>

        <Button
          variant="primary"
          icon={<RestartIcon />}
          label={t("Reset")}
          // disabled={isFetching}
          className={styles.reset_btn}
          onClick={handleResetFilters}
        />
      </div>

      <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      <DataTableComponent
        checkboxSelection={false}
        columns={renderColumns()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.sellers_table}
        rows={rows}
      />

      <Pagination
        onClick={paginationClick}
        onChangePageSize={handlePageSizeChange}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={pageSize}
      />
    </AppMainLayout>
  );
};

export default Clients;
