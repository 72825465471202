import React from "react";
import SignInPage from "./pages/SignInPage";
import Users from "./pages/Users";
import Clients from "./pages/Clients";
import ClientDetails from "./pages/ClientDetails";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccessToken } from "./api/selectors/tokenSelector";

import "./App.scss";

const App = () => {
  const token = useSelector(getAccessToken);
  const storedToken = localStorage.getItem('_session_token');
  const isAuthenticated = token && storedToken && token === storedToken;

  return (
    <Switch>
      <Route exact path="/" render={() => {
        // Если пользователь аутентифицирован и пытается зайти на /, редиректим на /app/users
        return isAuthenticated ? <Redirect to="/app/users" /> : <SignInPage />;
      }} />
      <Route path="/app/users" exact component={Users} />
      <Route path="/app/clients" exact component={Clients} />
      <Route path="/app/clients/:clientId" exact component={ClientDetails} />
    </Switch>
  );
};

export default App;
